
import React from 'react';

const Loading = () => {
  React.useEffect(() => {
    console.log("Loading component mounted");
  }, []);

  return (
    <div className="flex justify-center items-center p-4 min-h-[80px]" data-testid="loading-component">
      <div className="w-8 h-8 border-4 border-t-[#04627a] rounded-full animate-spin"></div>
    </div>
  );
};

export default Loading;
