
import { useState } from 'react';

const PaymentMethods = () => {
  const [visaError, setVisaError] = useState(false);
  const [masterCardError, setMasterCardError] = useState(false);
  const [secureImgError, setSecureImgError] = useState(false);

  return (
    <div className="flex items-center justify-center gap-4 flex-wrap">
      <div className="flex items-center gap-3">
        {!visaError ? (
          <img 
            src="/lovable-uploads/466d7025-0cbe-4b85-832f-f5c254c2abae.png" 
            alt="Visa" 
            className="h-6 w-auto object-contain bg-white p-1 rounded"
            onError={() => setVisaError(true)}
          />
        ) : (
          <div className="h-6 bg-white text-[#1a1f71] p-1 rounded flex items-center">
            <span className="font-bold text-xs">VISA</span>
          </div>
        )}
        
        {!masterCardError ? (
          <img 
            src="/lovable-uploads/6e9407ca-daad-4c31-bb74-504850f9caf8.png" 
            alt="Mastercard" 
            className="h-8 w-auto object-contain"
            onError={() => setMasterCardError(true)}
          />
        ) : (
          <div className="h-8 flex items-center justify-center">
            <span className="font-bold text-xs">MasterCard</span>
          </div>
        )}
      </div>
      <div className="flex items-center gap-2">
        {!secureImgError ? (
          <img
            src="/lovable-uploads/a7d501f2-f8a4-4655-a639-190b63545704.png"
            alt="Secure Payment"
            className="h-6 w-auto object-contain"
            onError={() => setSecureImgError(true)}
          />
        ) : (
          <div className="h-6 flex items-center justify-center">
            <span className="font-bold text-xs">🔒</span>
          </div>
        )}
        <p className="text-off-white text-sm">
          We accept all major cards with secure payment technology
        </p>
      </div>
    </div>
  );
};

export default PaymentMethods;
