
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Logo = () => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const [imgError, setImgError] = useState(false);
  const logoPath = '/lovable-uploads/b0768067-a1ff-4233-95bf-7cbd439ea87d.png';
  const fallbackLogo = 'https://shahhost.com/logos/shahhost-logo.png';

  useEffect(() => {
    // Preload the image
    const img = new Image();
    img.src = logoPath;
    
    img.onload = () => {
      setImgLoaded(true);
      setImgError(false);
    };
    
    img.onerror = () => {
      console.error("Logo failed to preload, trying fallback");
      // Try fallback image
      const fallbackImg = new Image();
      fallbackImg.src = fallbackLogo;
      
      fallbackImg.onload = () => {
        setImgLoaded(true);
        setImgError(false);
      };
      
      fallbackImg.onerror = () => {
        console.error("Fallback logo also failed to preload");
        setImgError(true);
        setImgLoaded(true); // Consider it loaded even if there's an error
      };
    };

    // Safety timeout - consider image loaded after 3s even if no events fire
    const timer = setTimeout(() => {
      if (!imgLoaded) {
        console.warn("Logo preload timeout reached, setting as loaded anyway");
        setImgLoaded(true);
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex-shrink-0 flex items-center">
      <Link to="/" className="block">
        {imgError ? (
          // Text fallback if image fails completely
          <div className="h-8 w-auto sm:h-10 md:h-12 flex items-center justify-center bg-[#04627a] text-white px-2 rounded">
            <span className="font-bold">SHAHhost</span>
          </div>
        ) : (
          <img 
            src={logoPath}
            alt="SHAHhost" 
            className="h-8 w-auto sm:h-10 md:h-12"
            onError={(e) => {
              console.error("Logo failed to load, trying fallback");
              e.currentTarget.src = fallbackLogo;
              // Add a second error handler for the fallback image
              e.currentTarget.onerror = () => {
                console.error("Fallback logo also failed");
                setImgError(true);
              };
            }}
          />
        )}
      </Link>
    </div>
  );
};

export default Logo;
