
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Search } from "lucide-react";
import { useIsMobile } from "@/hooks/use-mobile";
import { domainExtensions } from "@/constants/static-data";
import { useTranslation } from "react-i18next";

const DomainSearch = () => {
  const isMobile = useIsMobile();
  // Safely access i18n with defaults
  const { t, i18n } = useTranslation();
  const isRTL = i18n?.language === 'dr' || i18n?.language === 'ps';
  
  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const domain = form.domain?.value;
    if (domain) {
      window.location.href = `https://shahhost.net/order/index.php?/checkdomain/register-your-domain-now/&domain=${encodeURIComponent(domain)}`;
    }
  };

  return (
    <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-transparent py-6 md:py-8">
      <div className="container mx-auto px-4">
        <form onSubmit={handleSearch} className="flex flex-col items-center space-y-4">
          <div className="flex w-full max-w-3xl gap-2">
            <Input 
              name="domain"
              type="text" 
              placeholder={t('hero.search_domain', 'Search for domain...')}
              className={`h-10 bg-white/95 text-base ${isRTL ? 'text-right' : 'text-left'}`}
              required
              dir={isRTL ? 'rtl' : 'ltr'}
            />
            <Button 
              type="submit"
              size="default"
              className="h-10 bg-[#04627a] hover:bg-[#04627a]/90 text-white px-4 md:px-6 whitespace-nowrap"
            >
              <Search className="mr-2 h-4 w-4" />
              {!isMobile && t('hero.search', 'Search')}
            </Button>
          </div>
          <div className="flex flex-wrap justify-center gap-3 md:gap-4 text-white">
            {domainExtensions.map((domain, index) => (
              <div key={index} className="flex items-center space-x-1">
                <span className="text-sm md:text-base font-medium">{domain.ext}</span>
                <span className="text-[#4CAF50] text-xs md:text-sm font-bold">{domain.price}</span>
              </div>
            ))}
          </div>
        </form>
      </div>
    </div>
  );
};

export default DomainSearch;
