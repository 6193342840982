
import { Globe, ExternalLink } from "lucide-react";
import { useTranslation } from "react-i18next";

const DomainServices = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'dr' || i18n.language === 'ps';

  return (
    <div>
      <h3 className={`text-xl font-bold mb-4 flex items-center gap-2 ${isRTL ? 'flex-row-reverse' : ''}`}>
        <Globe className="h-5 w-5 text-third" />
        {t('footer.domain_services')}
      </h3>
      <ul className="space-y-2">
        {[
          { key: 'domain_registration', href: 'https://shahhost.net/order/index.php?/checkdomain/register-your-domain-now/' },
          { key: 'domain_transfer', href: 'https://shahhost.net/order/index.php?/checkdomain/register-your-domain-now/#transfer' },
          { key: 'bulk_domain_transfer', href: 'https://shahhost.net/order/index.php?/checkdomain/register-your-domain-now/#transfer' },
          { key: 'whois_lookup', href: 'https://www.shahhost.net/order/index.php?/whoislookup/' },
          { key: 'premium_domains', href: 'https://shahhost.net/order/index.php?/checkdomain/register-your-domain-now/' }
        ].map((item) => (
          <li key={item.key}>
            <a href={item.href}
               className={`text-off-white hover:text-third flex items-center gap-2 ${isRTL ? 'flex-row-reverse justify-end' : ''}`}>
              <ExternalLink className="h-4 w-4" />
              {t(item.key)}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DomainServices;
