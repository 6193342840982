
import { useCallback, useEffect, useState } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { useIsMobile } from "@/hooks/use-mobile";

const ParticlesBackground = () => {
  const isMobile = useIsMobile();
  const [isVisible, setIsVisible] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsVisible(scrollPosition < 500);
    };

    // Use passive listener to improve performance
    window.addEventListener('scroll', handleScroll, { passive: true });
    
    // Delay particles initialization to improve initial page load
    const timer = setTimeout(() => setIsLoaded(true), 1000);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timer);
    };
  }, []);

  const particlesInit = useCallback(async (engine: any) => {
    console.log("Initializing tsParticles engine");
    try {
      // Use loadFull for better compatibility
      await loadFull(engine);
      console.log("tsParticles engine initialized successfully");
    } catch (error) {
      console.error("Failed to initialize tsParticles:", error);
    }
  }, []);

  if (!isVisible || !isLoaded) return null;

  return (
    <Particles
      className="absolute inset-0"
      id="tsparticles"
      init={particlesInit}
      options={{
        fullScreen: false,
        fpsLimit: 30, // Keep reduced FPS for better performance
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.3,
            width: 1,
          },
          move: {
            direction: "none",
            enable: true,
            outModes: {
              default: "bounce",
            },
            random: false,
            speed: isMobile ? 0.5 : 1, // Reduced speed
            straight: false,
          },
          number: {
            density: {
              enable: true,
            },
            value: isMobile ? 30 : 50, // Reduced particle count
          },
          opacity: {
            value: 0.3,
          },
          shape: {
            type: "circle",
          },
          size: {
            value: { min: 1, max: 2 },
          },
        },
        detectRetina: true,
      }}
    />
  );
};

export default ParticlesBackground;
