import * as React from "react";
import PricingCard from "../../PricingCard";
import type { HostingPackage } from "@/types/hosting";

interface HostingPackagesGridProps {
  packages: HostingPackage[];
  isYearly?: boolean;
}

const HostingPackagesGrid: React.FC<HostingPackagesGridProps> = ({ 
  packages,
  isYearly = false
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6">
      {packages.map((pkg) => (
        <PricingCard key={pkg.id} pkg={pkg} isYearly={isYearly} />
      ))}
    </div>
  );
};

export default HostingPackagesGrid;