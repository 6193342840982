
import { Rocket, Globe, Mail, Lock, Laptop, Shapes, HardDrive, Server, Shield } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface ServicesNavProps {
  isOpen: boolean;
}

const ServicesNav = ({ isOpen }: ServicesNavProps) => {
  const { t } = useTranslation();

  const services = [
    { 
      icon: <Rocket className="h-4 w-4" />, 
      label: t('services.shared_hosting'),
      href: "https://shahhost.net/order/index.php?/cart/ssd-hosting/"
    },
    { 
      icon: <Globe className="h-4 w-4" />, 
      label: t('services.domain_registration'),
      href: "https://shahhost.net/order/index.php?/checkdomain/register-your-domain-now/"
    },
    { 
      icon: <Mail className="h-4 w-4" />, 
      label: t('services.email_hosting'),
      href: "https://shahhost.net/order/index.php?/cart/business-email-pro/"
    },
    { 
      icon: <Lock className="h-4 w-4" />, 
      label: t('services.ssl_certificates'),
      href: "https://shahhost.net/order/index.php?/cart/ssl-certificates-/"
    },
    { 
      icon: <Laptop className="h-4 w-4" />, 
      label: t('services.all_in_one'),
      href: "https://shahhost.net/order/index.php?/cart/all-in-one-website-packages/"
    },
    { 
      icon: <Shapes className="h-4 w-4" />, 
      label: t('services.branding'),
      href: "https://shahhost.net/order/index.php?/cart/branding-packages/"
    },
    { 
      icon: <HardDrive className="h-4 w-4" />, 
      label: t('services.vps'),
      href: "https://shahhost.net/order/index.php?/cart/vps-hosting/"
    },
    { 
      icon: <Server className="h-4 w-4" />, 
      label: t('services.dedicated'),
      href: "https://shahhost.net/order/index.php?/cart/dedicated-servers/"
    },
    { 
      icon: <Shield className="h-4 w-4" />, 
      label: t('services.reseller'),
      href: "https://shahhost.net/order/index.php?/cart/reseller-hosing/"
    },
  ];

  return (
    <div className="bg-secondary text-white relative z-40">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="hidden md:flex items-center justify-between h-12">
          {services.map((service, index) => (
            <a 
              key={index} 
              href={service.href}
              className="flex items-center gap-2 text-sm hover:text-third"
            >
              {service.icon}
              <span>{service.label}</span>
            </a>
          ))}
        </div>

        {isOpen && (
          <div className="md:hidden fixed top-[50vh] left-0 right-0 bg-secondary shadow-lg rounded-t-xl border-t border-gray-700 overflow-y-auto max-h-[50vh] overscroll-contain">
            <div className="py-4 space-y-2">
              {services.map((service, index) => (
                <a 
                  key={index} 
                  href={service.href}
                  className="flex items-center gap-2 px-6 py-3 text-sm hover:text-third hover:bg-gray-800"
                >
                  {service.icon}
                  <span>{service.label}</span>
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServicesNav;

