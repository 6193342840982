
import * as React from "react";
import { Tabs, TabsContent } from "@/components/ui/tabs";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import TabButtons from "./tabs/TabButtons";
import PricingToggle from "./toggle/PricingToggle";
import HostingHeader from "./sections/hosting/HostingHeader";
import HostingPackagesGrid from "./sections/hosting/HostingPackagesGrid";
import { useTranslation } from "react-i18next";
import type { HostingGroup, HostingPackage, TechIcon } from "@/types/hosting";
import { useToast } from "@/hooks/use-toast";
import type { Json } from "@/integrations/supabase/types";

const PricingTabs = () => {
  const [activeTab, setActiveTab] = React.useState("");
  const [isYearly, setIsYearly] = React.useState(false);
  const { t } = useTranslation('pricing');
  const { toast } = useToast();

  const { data: groups, isLoading: isLoadingGroups } = useQuery({
    queryKey: ['hosting-groups'],
    queryFn: async () => {
      try {
        const { data, error } = await supabase
          .from('hosting_groups')
          .select('*')
          .eq('is_active', true)
          .order('order_index', { ascending: true });
        
        if (error) {
          console.error("Error fetching hosting groups:", error);
          toast({
            title: "Error",
            description: "Failed to load hosting groups",
            variant: "destructive"
          });
          return [];
        }
        
        return data.map(group => ({
          ...group,
          tech_icons: (group.tech_icons as Json[] || []).map(icon => ({
            name: String((icon as any)?.name || ''),
            icon: String((icon as any)?.icon || '')
          })),
          translations: group.translations || {}
        })) as HostingGroup[];
      } catch (error) {
        console.error("Error in groups query:", error);
        return [];
      }
    },
    retry: false
  });

  const { data: packages, isLoading: isLoadingPackages } = useQuery({
    queryKey: ['hosting-packages'],
    queryFn: async () => {
      try {
        const { data, error } = await supabase
          .from('hosting_packages')
          .select('*')
          .eq('is_active', true)
          .order('order_index', { ascending: true });
        
        if (error) {
          console.error("Error fetching hosting packages:", error);
          toast({
            title: "Error",
            description: "Failed to load hosting packages",
            variant: "destructive"
          });
          return [];
        }
        
        return data as HostingPackage[];
      } catch (error) {
        console.error("Error in packages query:", error);
        return [];
      }
    },
    retry: false
  });

  React.useEffect(() => {
    if (groups && groups.length > 0) {
      setActiveTab(groups[0].slug);
    }
  }, [groups]);

  if (isLoadingGroups || isLoadingPackages) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 mx-auto mb-4"></div>
          <p className="text-gray-600">Loading pricing information...</p>
        </div>
      </div>
    );
  }

  const getPackagesByGroup = (groupId: string) => {
    return packages?.filter(pkg => pkg.group_id === groupId) || [];
  };

  return (
    <section className="py-8 md:py-16 bg-[#333333]">
      <div className="container mx-auto px-4 md:px-8">
        <div className="text-center mb-12">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-[#03a140] mb-4">
            {t('title')}
          </h2>
          <p className="text-gray-100 max-w-3xl mx-auto">
            {t('subtitle')}
          </p>
        </div>
        <div className="bg-white rounded-lg shadow-md mx-2 md:mx-0">
          <Tabs value={activeTab} onValueChange={setActiveTab}>
            <TabButtons tabs={groups?.map(g => g.slug) || []} />
            <div className="p-6">
              {groups?.map((group) => (
                <TabsContent key={group.id} value={group.slug}>
                  <HostingHeader 
                    group={group}
                    techIcons={group.tech_icons}
                  />
                  <div className="flex justify-center mb-8">
                    <PricingToggle isYearly={isYearly} onToggle={setIsYearly} />
                  </div>
                  <HostingPackagesGrid 
                    packages={getPackagesByGroup(group.id)}
                    isYearly={isYearly}
                  />
                </TabsContent>
              ))}
            </div>
          </Tabs>
        </div>
      </div>
    </section>
  );
};

export default PricingTabs;
