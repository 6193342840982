
import { useTranslation } from "react-i18next";
import { useState } from "react";

const WhyChooseUs = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'dr' || i18n.language === 'ps';
  const [iconErrors, setIconErrors] = useState<Record<number, boolean>>({});

  const handleIconError = (index: number) => {
    setIconErrors(prev => ({ ...prev, [index]: true }));
    console.error(`Feature icon ${index} failed to load`);
  };

  const features = [
    {
      icon: "/lovable-uploads/5f009ab2-955d-47b0-b0b8-4f0c57299cfd.png",
      title: t('features.support.title', '24/7 Support'),
      description: t('features.support.description', 'Round-the-clock assistance for all your hosting needs')
    },
    {
      icon: "/lovable-uploads/08a27860-5eae-403e-8e4b-7845eb6abf02.png",
      title: t('features.value.title', 'Pay Less Get More'),
      description: t('features.value.description', 'Affordable hosting solutions with premium features')
    },
    {
      icon: "/lovable-uploads/2393a6c7-d7d1-43c0-8dbc-0a5f05f9c60d.png",
      title: t('features.experience.title', '18 Years Experience'),
      description: t('features.experience.description', 'Nearly two decades of industry expertise')
    },
    {
      icon: "/lovable-uploads/0e631e8f-50c1-4a46-9e3c-7c5fb1ba87f2.png",
      title: t('features.delivery.title', 'On-Time Delivery'),
      description: t('features.delivery.description', 'Punctual project delivery without compromises')
    },
    {
      icon: "/lovable-uploads/11f5eafa-5916-4643-85ff-247112738446.png",
      title: t('features.servers.title', 'High Speed Servers'),
      description: t('features.servers.description', 'Lightning-fast servers optimized for performance')
    },
    {
      icon: "/lovable-uploads/a267dba3-fcd2-4c87-b27a-4c8654c85c87.png",
      title: t('features.security.title', 'High Security'),
      description: t('features.security.description', 'Advanced security measures to protect your data')
    },
    {
      icon: "/lovable-uploads/cea3d5bd-0752-48d1-8a2a-1bd0fd4c5634.png",
      title: t('features.clients.title', 'Up-to 500 Clients'),
      description: t('features.clients.description', 'Trusted by hundreds of satisfied customers')
    },
    {
      icon: "/lovable-uploads/85c187e5-f9fb-40bb-80bb-ca943eda4d0e.png",
      title: t('features.migration.title', 'Free Migration'),
      description: t('features.migration.description', 'Seamless migration from other hosting providers')
    }
  ];

  return (
    <section className="py-8 md:py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8 md:mb-12">
          <h2 className="text-2xl md:text-3xl font-bold text-primary mb-3 md:mb-4 tracking-tight">
            {t('why_choose_us.title', 'Why Choose Us')}<span className="text-third">?</span>
          </h2>
          <p className="text-sm md:text-base text-gray-600 max-w-3xl mx-auto text-center">
            {t('why_choose_us.description', 'Discover what makes SHAHhost the preferred choice for web hosting in Afghanistan')}
          </p>
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-off-white p-4 md:p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
              <div className="flex flex-col items-center text-center">
                <div className="mb-3 md:mb-4">
                  {iconErrors[index] ? (
                    <div className="w-20 h-20 bg-primary/10 rounded-full flex items-center justify-center text-primary font-semibold">
                      {feature.title.split(' ').map(word => word[0]).join('')}
                    </div>
                  ) : (
                    <img 
                      src={feature.icon} 
                      alt={feature.title} 
                      className="w-20 h-20" 
                      onError={() => handleIconError(index)}
                    />
                  )}
                </div>
                <h3 className="text-base md:text-lg font-semibold text-primary mb-2 text-center">{feature.title}</h3>
                <p className="text-xs md:text-sm text-gray-600 text-center">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
