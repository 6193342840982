
import React from "react";
import { Routes as RouterRoutes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import Loading from "@/components/Loading";

// Eager load Index page for faster initial load
import Index from "@/pages/Index";

// Lazy load other pages
const Admin = lazy(() => import("@/pages/Admin"));
const Auth = lazy(() => import("@/pages/Auth"));
const Portfolio = lazy(() => import("@/pages/Portfolio"));
const UserManagement = lazy(() => import("@/pages/UserManagement"));
const NotFound = lazy(() => import("@/pages/NotFound"));

const Routes = () => {
  console.log("Routes component rendering");
  React.useEffect(() => {
    console.log("Routes component mounted - rendering routes");
  }, []);

  return (
    <RouterRoutes>
      <Route path="/" element={<Index />} />
      <Route path="/admin" element={
        <Suspense fallback={<Loading />}>
          <Admin />
        </Suspense>
      } />
      <Route path="/auth" element={
        <Suspense fallback={<Loading />}>
          <Auth />
        </Suspense>
      } />
      <Route path="/portfolio" element={
        <Suspense fallback={<Loading />}>
          <Portfolio />
        </Suspense>
      } />
      <Route path="/admin/users" element={
        <Suspense fallback={<Loading />}>
          <UserManagement />
        </Suspense>
      } />
      
      {/* Catch-all route for 404 */}
      <Route path="*" element={
        <Suspense fallback={<Loading />}>
          <NotFound />
        </Suspense>
      } />
    </RouterRoutes>
  );
};

export default Routes;
