
// Initialize React globally before any other code runs
import React from 'react';
import ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';

// Immediately define global React without reassigning imports
if (typeof window !== 'undefined') {
  Object.defineProperty(window, 'React', { 
    value: React,
    writable: true,
    configurable: true
  });
  Object.defineProperty(window, 'ReactDOM', { 
    value: ReactDOM,
    writable: true,
    configurable: true
  });
  Object.defineProperty(window, 'ReactDOMClient', { 
    value: ReactDOMClient,
    writable: true,
    configurable: true
  });
  console.log('React globally initialized at the start of main.tsx');
}

// Now import styles and other dependencies
import './types/window';
import './index.css';
import { setupErrorHandlers } from './utils/errorHandlers';
import { removeLoadingElement } from './utils/loadingIndicator';
import App from './App';

// Add initialization flags to window
declare global {
  interface Window {
    __reactInitialized?: boolean;
  }
}

// Set up global error handlers
setupErrorHandlers();

// Function to initialize the React application
const initializeApp = () => {
  try {
    // Exit early if we've already initialized
    if (window.__reactInitialized) {
      console.log('React already initialized, skipping duplicate initialization');
      return;
    }
    
    console.log('Starting application initialization...');
    
    const rootElement = document.getElementById('root');
    if (!rootElement) {
      console.error('Root element not found');
      return;
    }

    // Create root and render
    console.log('Creating React root...');
    const root = ReactDOMClient.createRoot(rootElement);
    
    console.log('Rendering React application...');
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
    
    console.log('React application rendered successfully');
    window.__reactInitialized = true;
    
    // Remove loading element after successful render
    setTimeout(() => {
      removeLoadingElement();
    }, 100);
    
  } catch (error) {
    console.error('Failed to render the application:', error);
    const rootElement = document.getElementById('root');
    if (rootElement) {
      rootElement.innerHTML = `
        <div style="padding: 20px; text-align: center; font-family: system-ui, sans-serif;">
          <h2 style="color: #d32f2f;">Application Error</h2>
          <p>We encountered an issue while starting the application.</p>
          <p style="color: #666; font-size: 14px; margin-top: 8px;">${error instanceof Error ? error.message : 'Unknown error'}</p>
          <button onclick="window.location.reload()" 
                  style="background: #04627a; color: white; border: none; padding: 8px 16px; 
                        border-radius: 4px; cursor: pointer; margin-top: 16px;">
            Try Again
          </button>
        </div>
      `;
    }
    
    // Still try to remove loading element
    removeLoadingElement();
  }
};

// Update the appStarted function to work with our flags
if (typeof window !== 'undefined') {
  window.appStarted = function() {
    console.log('App started signal received');
    removeLoadingElement();
  };
}

// Initialize as soon as DOM is ready
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', () => {
    console.log('DOM fully loaded - starting React initialization');
    initializeApp();
  });
} else {
  // DOM already loaded, initialize now
  console.log('DOM already loaded - starting React initialization immediately');
  initializeApp();
}
