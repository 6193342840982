export const domainExtensions = [
  { ext: ".COM", price: "$13/YR" },
  { ext: ".AF", price: "$26/YR" },
  { ext: ".COM.AF", price: "$23/YR" },
  { ext: ".NET", price: "$13/YR" },
  { ext: ".ORG", price: "$13/YR" },
  { ext: ".ME", price: "$7/YR" }
] as const;

export const workingHours = {
  weekdays: "9:00 AM - 6:00 PM",
  saturday: "9:00 AM - 4:00 PM",
  sunday: "Closed"
} as const;

export const contactInfo = {
  afghanistan: {
    phones: ["+93-20-211-0123", "+93-799-888-952"],
    address: "B3-054, Daudzai Business Center\nMalik Asghar Square,\nKabul, Afghanistan."
  },
  usa: {
    phones: ["+1-346-270-5687"]
  },
  email: {
    sales: "sales@shahhost.com",
    billing: "billing@shahhost.com",
    support: "support@shahhost.com"
  }
} as const;