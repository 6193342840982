
import { useState, useEffect } from "react";
import ParticlesBackground from "./hero/ParticlesBackground";
import HeroCarousel from "./hero/HeroCarousel";
import DomainSearch from "./hero/DomainSearch";

const Hero = () => {
  const [bgAttempts, setBgAttempts] = useState(0);
  
  // Use the locally uploaded image
  const imageUrl = '/lovable-uploads/b8c4a463-64a0-4e4b-8b5b-09df0eca2b91.png';

  return (
    <section 
      className="relative min-h-[480px] md:min-h-[600px] overflow-hidden bg-[#04627a]"
      style={{
        backgroundImage: bgAttempts > 2
          ? `linear-gradient(135deg, rgba(4, 98, 122, 0.95) 0%, rgba(4, 98, 122, 0.8) 100%)` 
          : `
          linear-gradient(
            to right,
            rgba(4, 98, 122, 0.6) 0%,
            rgba(4, 98, 122, 0.4) 50%,
            rgba(4, 98, 122, 0.3) 100%
          ),
          linear-gradient(
            to bottom,
            transparent 0%,
            transparent 70%,
            rgba(51, 51, 51, 0.9) 100%
          ),
          url('${imageUrl}')
        `,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <ParticlesBackground />
      <HeroCarousel />
      <DomainSearch />
    </section>
  );
};

export default Hero;
