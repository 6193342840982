
import { User, BookOpen, ShoppingCart, Linkedin, Twitter, Youtube, Facebook, Home, Briefcase } from 'lucide-react';
import { Button } from '../ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useLanguage } from '@/contexts/LanguageContext';
import { useTranslation } from 'react-i18next';
import type { Language } from '@/contexts/LanguageContext';
const DesktopNav = () => {
  const {
    currentLanguage,
    setLanguage
  } = useLanguage();
  const {
    t
  } = useTranslation();
  const handleLanguageChange = (value: string) => {
    setLanguage(value as Language);
  };
  return <div className="hidden md:flex items-center space-x-4">
      <a href="/" className="flex items-center text-secondary hover:text-primary text-[13px]" aria-label="Home">
        <Home className="h-4 w-4 mr-1" />
        <span>{t('nav.home')}</span>
      </a>
      <a href="/portfolio" className="flex items-center text-secondary hover:text-primary text-[13px]" aria-label="Portfolio">
        <Briefcase className="h-4 w-4 mr-1" />
        <span>{t('nav.portfolio')}</span>
      </a>

      <div className="flex items-center space-x-4">
        <a href="https://www.shahhost.net/order/index.php?/tickets/new/" className="flex items-center text-secondary hover:text-primary text-[13px]" aria-label="Support">
          <User className="h-4 w-4 mr-1" />
          <span>{t('nav.support')}</span>
        </a>
        <a href="https://www.shahhost.net/order/index.php?/news" className="flex items-center text-secondary hover:text-primary text-[13px]" aria-label="Blog">
          <BookOpen className="h-4 w-4 mr-1" />
          <span>{t('nav.blog')}</span>
        </a>
      </div>

      <div className="flex items-center space-x-2 mx-7">
        <a href="https://linkedin.com/company/shahhost" className="text-primary hover:text-primary/80" aria-label="Follow us on LinkedIn">
          <Linkedin className="h-4 w-4" />
        </a>
        <a href="https://twitter.com/shahhost" className="text-primary hover:text-primary/80" aria-label="Follow us on Twitter">
          <Twitter className="h-4 w-4" />
        </a>
        <a href="https://youtube.com/shahhost" className="text-primary hover:text-primary/80" aria-label="Subscribe to our YouTube channel">
          <Youtube className="h-4 w-4" />
        </a>
        <a href="https://facebook.com/shahhost" className="text-primary hover:text-primary/80" aria-label="Follow us on Facebook">
          <Facebook className="h-4 w-4" />
        </a>
      </div>

      <Select value={currentLanguage} onValueChange={handleLanguageChange}>
        <SelectTrigger className="w-[120px] border-none focus:ring-0 bg-transparent" aria-label="Select Language">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="en">
            <div className="flex items-center">
              <img src="/lovable-uploads/ffaab820-01c6-4170-8a13-fd0995d903b4.png" alt="US Flag" className="w-4 h-4 mr-2 object-cover rounded-sm" />
              <span>English</span>
            </div>
          </SelectItem>
          <SelectItem value="dr">
            <div className="flex items-center">
              <img src="/lovable-uploads/fd269dce-f439-4fc8-a6f7-9f574af23ed3.png" alt="Afghanistan Flag" className="w-4 h-4 mr-2 object-cover rounded-sm" />
              <span>دری</span>
            </div>
          </SelectItem>
          <SelectItem value="ps">
            <div className="flex items-center">
              <img src="/lovable-uploads/fd269dce-f439-4fc8-a6f7-9f574af23ed3.png" alt="Afghanistan Flag" className="w-4 h-4 mr-2 object-cover rounded-sm" />
              <span>پشتو</span>
            </div>
          </SelectItem>
        </SelectContent>
      </Select>

      <div className="flex items-center space-x-2">
        <ShoppingCart aria-label="Shopping Cart" className="h-4 w-4 text-secondary hover:text-primary cursor-pointer my-[4px]" />
        <Button size="sm" className="text-white" onClick={() => window.location.href = 'https://shahhost.net/order/index.php?/clientarea/'} aria-label={t('nav.login')}>
          {t('nav.login')}
        </Button>
      </div>
    </div>;
};
export default DesktopNav;
