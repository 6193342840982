
import * as React from "react";
import { Switch } from "@/components/ui/switch";
import { useTranslation } from "react-i18next";

interface PricingToggleProps {
  isYearly: boolean;
  onToggle: (value: boolean) => void;
}

const PricingToggle: React.FC<PricingToggleProps> = ({ isYearly, onToggle }) => {
  const { t, i18n } = useTranslation('pricing');
  const isRTL = i18n.language === 'dr' || i18n.language === 'ps';

  return (
    <div className={`flex items-center justify-center gap-3 mt-2 mb-4 ${isRTL ? 'flex-row-reverse' : ''}`}>
      <span className={`text-sm font-medium ${!isYearly ? 'text-[#03a140]' : 'text-gray-400'}`}>
        {t('monthly', 'Monthly')}
      </span>
      <Switch
        checked={isYearly}
        onCheckedChange={onToggle}
        className="data-[state=checked]:bg-[#03a140]"
        aria-label={isYearly ? t('switch_to_monthly', 'Switch to monthly billing') : t('switch_to_yearly', 'Switch to yearly billing')}
      />
      <span className={`text-sm font-medium ${isYearly ? 'text-[#03a140]' : 'text-gray-400'}`}>
        {t('yearly', 'Yearly')}
      </span>
    </div>
  );
};

export default PricingToggle;
