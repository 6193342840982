
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://lldygdkwaypdalohudlp.supabase.co";
const SUPABASE_ANON_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxsZHlnZGt3YXlwZGFsb2h1ZGxwIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzgzMDQzMzgsImV4cCI6MjA1Mzg4MDMzOH0.V6gRuG_4wkTxCwWQaAZnSeg7C0jPBiKY1Lti08AIt9I";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_ANON_KEY, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: false,
    flowType: 'pkce',
    storage: typeof window !== 'undefined' ? window.localStorage : undefined,
    storageKey: 'supabase.auth.token',
  },
  global: {
    headers: {
      'apikey': SUPABASE_ANON_KEY,
    },
  },
});
