
import React, { useState } from "react";
import type { Json } from "@/integrations/supabase/types";

interface TechnologyIcon {
  name: string;
  icon?: string;
}

interface TechnologyIconsProps {
  icons: Json | TechnologyIcon[];
}

const TechnologyIcons: React.FC<TechnologyIconsProps> = ({ icons }) => {
  const [iconErrors, setIconErrors] = useState<Record<number, boolean>>({});

  if (!Array.isArray(icons) || icons.length === 0) {
    return null;
  }

  const handleIconError = (index: number) => {
    setIconErrors(prev => ({ ...prev, [index]: true }));
  };

  return (
    <div className="grid grid-cols-4 sm:grid-cols-4 gap-8 mb-8 mt-12 max-w-2xl mx-auto">
      {icons.map((item, index) => (
        <div
          key={index}
          className="flex flex-col items-center justify-center p-2 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors w-20 h-20"
        >
          {item.icon && !iconErrors[index] ? (
            <div className="w-12 h-12 flex items-center justify-center">
              <img 
                src={item.icon} 
                alt={item.name} 
                className="w-8 h-8 object-contain"
                onError={() => handleIconError(index)}
              />
            </div>
          ) : (
            <div className="w-12 h-12 flex items-center justify-center">
              <span className="text-sm font-medium text-center">{item.name}</span>
            </div>
          )}
          {item.icon && !iconErrors[index] && (
            <span className="text-xs font-medium mt-2 text-gray-700 text-center">
              {item.name}
            </span>
          )}
        </div>
      ))}
    </div>
  );
};

export default TechnologyIcons;
