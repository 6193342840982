
import React, { Suspense } from "react";
import Navbar from "@/components/Navbar";
import Hero from "@/components/Hero";
import PricingTabs from "@/components/pricing/PricingTabs";
import WhyChooseUs from "@/components/WhyChooseUs";
import Footer from "@/components/Footer";
import Loading from "@/components/Loading";

// Lazy load less important components
const ServicesTab = React.lazy(() => import("@/components/ServicesTab"));
const ClientLogos = React.lazy(() => import("@/components/ClientLogos"));
const About = React.lazy(() => import("@/components/About"));
const WhatsAppButton = React.lazy(() => import("@/components/WhatsAppButton"));

const Index = () => {
  React.useEffect(() => {
    console.log("Index component mounted");
  }, []);

  return (
    <div className="min-h-screen">
      <Navbar />
      <Hero />
      <PricingTabs />
      <WhyChooseUs />
      
      <Suspense fallback={<Loading />}>
        <ServicesTab />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <ClientLogos />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <About />
      </Suspense>

      <Footer />

      <Suspense fallback={<Loading />}>
        <WhatsAppButton />
      </Suspense>
    </div>
  );
};

export default Index;
