
import React from "react";
import { TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Server, Cloud, Database, Mail, Globe } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";

interface TabButtonsProps {
  tabs: string[];
}

const getIconComponent = (tabId: string) => {
  switch (tabId.toLowerCase()) {
    case 'shared':
      return Server;
    case 'email-hosting':
      return Mail;
    case 'cloud-vps':
      return Cloud;
    case 'dedicated-instance':
      return Database;
    case 'all-in-one':
      return Globe;
    default:
      return Server;
  }
};

const TabButtons: React.FC<TabButtonsProps> = ({ tabs }) => {
  const { i18n } = useTranslation('pricing');
  const isRTL = i18n.language === 'dr' || i18n.language === 'ps';
  const currentLang = i18n.language as 'en' | 'dr' | 'ps';

  const { data: groups } = useQuery({
    queryKey: ['hosting-groups'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('hosting_groups')
        .select('*')
        .eq('is_active', true)
        .order('order_index', { ascending: true });
      
      if (error) throw error;
      return data;
    }
  });

  const getTranslatedTitle = (tabId: string) => {
    const group = groups?.find(g => g.slug === tabId);
    
    if (!group) {
      return tabId.split('-').map(word => 
        word.charAt(0).toUpperCase() + word.slice(1)
      ).join(' ');
    }

    // If current language is English, use the default name
    if (currentLang === 'en') {
      return group.name;
    }

    // Get translation for current language
    const translation = group.translations?.[currentLang]?.name;
    
    // If translation exists, use it; otherwise fall back to default name
    return translation || group.name;
  };

  return (
    <div className="overflow-x-auto px-4 py-2">
      <TabsList className="inline-flex w-auto min-w-full md:w-full bg-gray-100 p-1 rounded-t-lg">
        {tabs.map((tabId) => {
          const IconComponent = getIconComponent(tabId);
          return (
            <TabsTrigger 
              key={tabId}
              value={tabId} 
              className={`flex-1 data-[state=active]:bg-[#04627a] data-[state=active]:text-white data-[state=active]:shadow-md 
                text-sm md:text-base flex items-center justify-center gap-2 whitespace-nowrap px-4 py-3 
                transition-all font-semibold rounded-t-md hover:bg-gray-200
                data-[state=inactive]:text-gray-600 data-[state=active]:border-t-2 data-[state=active]:border-x-2
                data-[state=active]:border-[#04627a] ${isRTL ? 'flex-row-reverse' : ''}`}
            >
              <IconComponent className="w-4 h-4 flex-shrink-0" />
              <span>{getTranslatedTitle(tabId)}</span>
            </TabsTrigger>
          );
        })}
      </TabsList>
    </div>
  );
};

export default TabButtons;

