
import * as React from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Check } from "lucide-react";
import type { HostingPackage } from "@/types/hosting";
import { useTranslation } from "react-i18next";

interface PricingCardProps {
  pkg: HostingPackage;
  isYearly?: boolean;
}

const PricingCard: React.FC<PricingCardProps> = ({ pkg, isYearly = false }) => {
  const { t, i18n } = useTranslation('pricing');
  const isRTL = i18n.language === 'dr' || i18n.language === 'ps';
  const currentLang = i18n.language as 'en' | 'dr' | 'ps';

  // Handle potentially undefined package or translations
  if (!pkg) {
    console.warn('PricingCard received undefined package');
    return null;
  }

  const getTranslatedContent = (field: 'name' | 'title' | 'description' | 'button_text') => {
    try {
      // Try to get translation in current language
      if (pkg.translations?.[currentLang]?.[field]) {
        return pkg.translations[currentLang][field];
      }
      // Fallback to English translation
      if (pkg.translations?.['en']?.[field]) {
        return pkg.translations['en'][field];
      }
      // Fallback to original field value
      switch (field) {
        case 'title':
          return pkg.title || 'Hosting Package';
        case 'description':
          return pkg.description || 'Web hosting package';
        case 'button_text':
          return pkg.button_text || 'View Details';
        default:
          return 'N/A';
      }
    } catch (error) {
      console.error(`Error getting translation for ${field}:`, error);
      // Final fallback values
      switch (field) {
        case 'title': return 'Hosting Package';
        case 'description': return 'Web hosting package';
        case 'button_text': return 'View Details';
        default: return 'N/A';
      }
    }
  };

  const calculatePrice = (price: number): string => {
    if (!price && price !== 0) return '0.00';
    return isYearly ? (price * 12).toFixed(2) : price.toFixed(2);
  };

  const displayPrice = calculatePrice(pkg.price);
  const displayRegularPrice = pkg.regular_price ? calculatePrice(pkg.regular_price) : undefined;
  const displayPeriod = isYearly ? t('per_year', { defaultValue: 'per year' }) : t('per_month', { defaultValue: 'per month' });

  const discountPercentage = pkg.regular_price && pkg.price
    ? Math.round(((pkg.regular_price - pkg.price) / pkg.regular_price) * 100)
    : null;

  const handleButtonClick = () => {
    if (pkg.button_link) {
      window.location.href = pkg.button_link;
    }
  };

  // Safely access features with fallback
  const features = Array.isArray(pkg.features) ? pkg.features : [];

  return (
    <Card className="relative overflow-hidden bg-white border-gray-200 h-full">
      {discountPercentage && discountPercentage > 0 && (
        <div className={`absolute top-1 ${isRTL ? 'left-4' : 'right-4'} bg-[#4CAF50] text-white px-2 py-1 rounded-full text-sm font-medium`}>
          {t('save', { defaultValue: 'Save' })} {discountPercentage}%
        </div>
      )}
      <CardHeader className={`border-b border-gray-100 pb-4 bg-off-white text-center`}>
        <CardTitle className="text-xl font-bold text-[#04627a]">
          {getTranslatedContent('title')}
        </CardTitle>
        <CardDescription className="text-gray-600">
          {getTranslatedContent('description')}
        </CardDescription>
        <div className="mt-4 flex items-baseline justify-center gap-1">
          <span className="text-lg">$</span>
          <span className="text-4xl font-bold text-[#4CAF50]">{displayPrice}</span>
          <span className="text-gray-500">{displayPeriod}</span>
        </div>
        {displayRegularPrice && (
          <div className="text-sm text-gray-400 line-through">
            {t('regular', { defaultValue: 'Regular' })}: ${displayRegularPrice}
          </div>
        )}
      </CardHeader>
      <CardContent className="pt-6 bg-[#fafafa]">
        <ul className={`space-y-3 ${isRTL ? 'text-right' : 'text-left'}`}>
          {features.map((feature, idx) => (
            <li key={idx} className="flex items-center gap-2 text-sm text-gray-600">
              <Check className="h-4 w-4 text-[#4CAF50] flex-shrink-0" />
              <span>{feature}</span>
            </li>
          ))}
        </ul>
        <Button 
          className="w-full mt-6 bg-[#04627a] hover:bg-[#035269] text-white"
          onClick={handleButtonClick}
        >
          {getTranslatedContent('button_text') || t('view_plans', { defaultValue: 'View Plans' })}
        </Button>
      </CardContent>
    </Card>
  );
};

export default PricingCard;
