import React from "react";
import { ArrowUp } from "lucide-react";
import HelpSupport from "./footer/HelpSupport";
import DomainServices from "./footer/DomainServices";
import HostingSolutions from "./footer/HostingSolutions";
import EmailSolutions from "./footer/EmailSolutions";
import Newsletter from "./footer/Newsletter";
import ContactInfo from "./footer/ContactInfo";
import PaymentMethods from "./footer/PaymentMethods";
import SocialLinks from "./footer/SocialLinks";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const {
    t,
    i18n
  } = useTranslation();
  const isRTL = i18n.language === 'dr' || i18n.language === 'ps';
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  return <footer className="bg-secondary text-[#eee] pt-12 pb-6" dir={isRTL ? 'rtl' : 'ltr'}>
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-6 mb-8">
          <HelpSupport />
          <DomainServices />
          <HostingSolutions />
          <EmailSolutions />
          <div className={`${!isRTL ? 'text-left' : ''}`}>
            <h3 className="text-lg font-bold mb-3">{t('footer.about_us')}</h3>
            <ul className="space-y-1.5">
              <li>
                <a href="https://www.shahhost.net/order/index.php?/page/about-us/" className="text-off-white hover:text-third text-sm">
                  {t('footer.company_overview')}
                </a>
              </li>
              <li>
                <a href="/portfolio" className="text-off-white hover:text-third text-sm">
                  {t('footer.portfolio')}
                </a>
              </li>
              <li>
                <a href="https://shahhost.net/order/index.php?/clientarea/" className="text-off-white hover:text-third text-sm">
                  {t('footer.client_portal')}
                </a>
              </li>
              <li>
                <a href="https://www.shahhost.net/order/index.php?/knowledgebase/category/12/terms--amp--conditions/" className="text-off-white hover:text-third text-sm">
                  {t('footer.terms')}
                </a>
              </li>
              <li>
                <a href="https://www.shahhost.net/order/index.php?/page/acceptable-use-policy--aup-/" className="text-off-white hover:text-third text-sm">
                  {t('footer.aup')}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 border-t border-white/10 pt-6 mb-6">
          <ContactInfo />
          <Newsletter />
        </div>

        <div className="bg-primary border-t border-white/10 pt-6 px-4 pb-5">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="text-off-white text-sm">
              {t('footer.rights_reserved')}
            </div>
            <div className="flex flex-col md:flex-row items-center gap-4">
              <PaymentMethods />
              <SocialLinks />
            </div>
          </div>
        </div>

        
      </div>
    </footer>;
};
export default Footer;