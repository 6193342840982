
import { Mail, Phone, MapPin } from "lucide-react";
import { useTranslation } from "react-i18next";

const ContactInfo = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'dr' || i18n.language === 'ps';
  
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div>
        <h3 className={`text-lg font-semibold mb-3 ${isRTL ? 'text-right' : 'text-left'}`}>
          {t('common:footer.contact', 'Contact Us')}
        </h3>
        <div className="space-y-4">
          <div>
            <h4 className={`text-white text-sm font-medium mb-2 ${isRTL ? 'text-right' : 'text-left'}`}>
              {t('contact.afghanistan_office')}
            </h4>
            <a href="tel:+93202110123" 
               className={`flex items-center text-white hover:text-third text-base py-2 ${isRTL ? 'flex-row-reverse justify-end' : ''}`}
               aria-label="Call Afghanistan office at +93-20-211-0123">
              <Phone className="h-4 w-4 flex-shrink-0" />
              <span className="mx-2">+93-20-211-0123</span>
            </a>
            <a href="tel:+93799888952" 
               className={`flex items-center text-white hover:text-third text-base py-2 ${isRTL ? 'flex-row-reverse justify-end' : ''}`}
               aria-label="Call Afghanistan mobile at +93-799-888-952">
              <Phone className="h-4 w-4 flex-shrink-0" />
              <span className="mx-2">+93-799-888-952</span>
            </a>
          </div>
          
          <div>
            <h4 className={`text-white text-sm font-medium mb-2 ${isRTL ? 'text-right' : 'text-left'}`}>
              {t('contact.usa_office')}
            </h4>
            <a href="tel:+13462705687" 
               className={`flex items-center text-white hover:text-third text-base py-2 ${isRTL ? 'flex-row-reverse justify-end' : ''}`}
               aria-label="Call USA office at +1-346-270-5687">
              <Phone className="h-4 w-4 flex-shrink-0" />
              <span className="mx-2">+1-346-270-5687</span>
            </a>
          </div>
        </div>
      </div>

      <div>
        <div className="space-y-4">
          <div>
            <h4 className={`text-white text-sm font-medium mb-2 ${isRTL ? 'text-right' : 'text-left'}`}>
              {t('contact.email')}
            </h4>
            <a href="mailto:sales@shahhost.com" 
               className={`flex items-center text-white hover:text-third text-base py-2 ${isRTL ? 'flex-row-reverse justify-end' : ''}`}
               aria-label="Email sales department">
              <Mail className="h-4 w-4 flex-shrink-0" />
              <span className="mx-2">sales@shahhost.com</span>
            </a>
            <a href="mailto:billing@shahhost.com" 
               className={`flex items-center text-white hover:text-third text-base py-2 ${isRTL ? 'flex-row-reverse justify-end' : ''}`}
               aria-label="Email billing department">
              <Mail className="h-4 w-4 flex-shrink-0" />
              <span className="mx-2">billing@shahhost.com</span>
            </a>
            <a href="mailto:support@shahhost.com" 
               className={`flex items-center text-white hover:text-third text-base py-2 ${isRTL ? 'flex-row-reverse justify-end' : ''}`}
               aria-label="Email support team">
              <Mail className="h-4 w-4 flex-shrink-0" />
              <span className="mx-2">support@shahhost.com</span>
            </a>
          </div>

          <address className="not-italic">
            <h4 className={`text-white text-sm font-medium mb-2 ${isRTL ? 'text-right' : 'text-left'}`}>
              {t('contact.address')}
            </h4>
            <div className={`flex items-start text-white text-base ${isRTL ? 'flex-row-reverse' : ''}`}>
              <MapPin className="h-4 w-4 mt-0.5 flex-shrink-0" />
              <span className="mx-2">
                B3-054, Daudzai Business Center<br />
                Kabul, Afghanistan
              </span>
            </div>
          </address>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
