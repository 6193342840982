
import { Mail, ExternalLink } from "lucide-react";
import { useTranslation } from "react-i18next";

const EmailSolutions = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'dr' || i18n.language === 'ps';
  
  return (
    <div>
      <h3 className={`text-xl font-bold mb-4 flex items-center gap-2 ${isRTL ? 'flex-row-reverse' : ''}`}>
        <Mail className="h-5 w-5 text-third" />
        {t('footer.email_solutions')}
      </h3>
      <ul className="space-y-2">
        {[
          'business_email',
          'titan_email',
          'cpanel_webmail',
          'enterprise_email'
        ].map((key) => (
          <li key={key}>
            <a href="https://shahhost.net/order/index.php?/cart/business-email-pro/" 
               className={`text-off-white hover:text-third flex items-center gap-2 ${isRTL ? 'flex-row-reverse justify-end' : ''}`}>
              <ExternalLink className="h-4 w-4" />
              {t(key)}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EmailSolutions;
