
import { Menu, User } from 'lucide-react';
import { Button } from '../ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useLanguage } from '@/contexts/LanguageContext';
import { useTranslation } from 'react-i18next';
import type { Language } from '@/contexts/LanguageContext';

interface MobileNavProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const MobileNav = ({ isOpen, setIsOpen }: MobileNavProps) => {
  const { currentLanguage, setLanguage } = useLanguage();
  const { t } = useTranslation();

  const handleLanguageChange = (value: string) => {
    setLanguage(value as Language);
  };

  return (
    <>
      <div className="flex items-center gap-4 md:hidden">
        <div className="flex items-center gap-3">
          <a href="https://shahhost.net/order/index.php?/clientarea/">
            <User size={20} className="text-secondary hover:text-primary cursor-pointer" />
          </a>
          <Select value={currentLanguage} onValueChange={handleLanguageChange}>
            <SelectTrigger className="w-[70px] border-none focus:ring-0 p-0 bg-transparent">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="en" className="cursor-pointer">
                <div className="flex items-center">
                  <img
                    src="/lovable-uploads/ffaab820-01c6-4170-8a13-fd0995d903b4.png"
                    alt="US Flag"
                    className="w-4 h-4 mr-2 object-cover rounded-sm"
                  />
                  <span>EN</span>
                </div>
              </SelectItem>
              <SelectItem value="dr" className="cursor-pointer">
                <div className="flex items-center">
                  <img
                    src="/lovable-uploads/fd269dce-f439-4fc8-a6f7-9f574af23ed3.png"
                    alt="Afghanistan Flag"
                    className="w-4 h-4 mr-2 object-cover rounded-sm"
                  />
                  <span>DR</span>
                </div>
              </SelectItem>
              <SelectItem value="ps" className="cursor-pointer">
                <div className="flex items-center">
                  <img
                    src="/lovable-uploads/fd269dce-f439-4fc8-a6f7-9f574af23ed3.png"
                    alt="Afghanistan Flag"
                    className="w-4 h-4 mr-2 object-cover rounded-sm"
                  />
                  <span>PS</span>
                </div>
              </SelectItem>
            </SelectContent>
          </Select>
        </div>
        <button 
          onClick={() => setIsOpen(!isOpen)} 
          className="bg-primary p-2 rounded-md hover:bg-primary/90 transition-colors"
        >
          <Menu size={24} className="text-[#eee]" />
        </button>
      </div>

      {isOpen && (
        <div className="absolute top-16 left-0 right-0 md:hidden bg-white shadow-lg z-50">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <a href="/" className="block px-3 py-2 text-secondary hover:text-primary">{t('nav.home')}</a>
            <a href="/portfolio" className="block px-3 py-2 text-secondary hover:text-primary">{t('nav.portfolio')}</a>
            <a href="https://www.shahhost.net/order/index.php?/tickets/new/" className="block px-3 py-2 text-secondary hover:text-primary">{t('nav.support')}</a>
            <a href="https://www.shahhost.net/order/index.php?/news" className="block px-3 py-2 text-secondary hover:text-primary">{t('nav.blog')}</a>
            <div className="px-3 py-2">
              <Button 
                className="w-full text-[#eee] h-9 text-sm"
                onClick={() => window.location.href = 'https://shahhost.net/order/index.php?/clientarea/'}
              >
                {t('nav.login')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileNav;
