
// Function to safely remove the loading element
export const removeLoadingElement = () => {
  console.log('Attempting to remove loading element from loadingIndicator.ts');
  try {
    // Track if we've already removed the element to prevent duplicate removals
    if (window.__loadingElementRemoved) {
      console.log('Loading element was already removed, skipping');
      return;
    }

    const loadingEl = document.getElementById('root-loading');
    if (!loadingEl) {
      console.log('Loading element not found, may have been already removed');
      window.__loadingElementRemoved = true;
      return;
    }
    
    // Add fade-out class first
    loadingEl.classList.add('fade-out');
    
    // Remove after a short delay to allow for animation
    setTimeout(() => {
      try {
        if (loadingEl.parentNode) {
          loadingEl.parentNode.removeChild(loadingEl);
          console.log('Loading element removed with animation');
        } else {
          console.log('Loading element has no parent node, cannot remove');
        }
      } catch (e) {
        console.error('Error removing loading element:', e);
      }
      window.__loadingElementRemoved = true;
    }, 300);
  } catch (error) {
    console.error('Error in removeLoadingElement:', error);
    window.__loadingElementRemoved = true; // Mark as removed anyway to prevent further attempts
  }
};

// Create loading element for before app initialization
export const createLoadingElement = () => {
  // Skip creating a loading element since it's already in index.html
  console.log('Loading element already exists in HTML, skipping creation');
};

// Function to check if React has successfully initialized
export const checkReactInitialized = () => {
  try {
    const rootElement = document.getElementById('root');
    return !!rootElement && rootElement.hasChildNodes() && rootElement.children.length > 0;
  } catch (error) {
    console.error('Error checking if React is initialized:', error);
    return false;
  }
};

// No need to redeclare Window interface here as it's already in types/window.ts
