
import { Facebook, Twitter, Linkedin, Youtube } from "lucide-react";

const SocialLinks = () => {
  return (
    <div className="flex space-x-4">
      <a href="https://facebook.com/shahhost" 
         className="text-off-white hover:text-third"
         aria-label="Visit our Facebook page">
        <Facebook className="h-5 w-5" />
      </a>
      <a href="https://twitter.com/shahhost" 
         className="text-off-white hover:text-third"
         aria-label="Follow us on Twitter">
        <Twitter className="h-5 w-5" />
      </a>
      <a href="https://linkedin.com/company/shahhost" 
         className="text-off-white hover:text-third"
         aria-label="Connect with us on LinkedIn">
        <Linkedin className="h-5 w-5" />
      </a>
      <a href="https://youtube.com/shahhost" 
         className="text-off-white hover:text-third"
         aria-label="Subscribe to our YouTube channel">
        <Youtube className="h-5 w-5" />
      </a>
    </div>
  );
};

export default SocialLinks;
