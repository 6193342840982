
import { Carousel, CarouselContent, CarouselItem } from "@/components/ui/carousel";
import HeroSlide from "./HeroSlide";
import { useEffect, useState } from "react";
import { useIsMobile } from "@/hooks/use-mobile";

const slides = [
  {
    title: "ssd_shared_hosting",
    description: "Experience lightning-fast NVMe SSD hosting with cPanel simplicity",
    primaryButton: { 
      text: "Get Started",
      link: "https://shahhost.net/order/index.php?/cart/ssd-hosting/"
    },
    secondaryButton: { 
      text: "Learn More",
      link: "https://shahhost.net/order/index.php?/cart/ssd-hosting/"
    },
    price: {
      amount: "1.25",
      period: "Per Month"
    },
    discount: "25%"
  },
  {
    title: "email_hosting",
    description: "Professional email hosting solutions for your business",
    primaryButton: { 
      text: "Get Started",
      link: "https://shahhost.net/order/index.php?/cart/business-email-pro/"
    },
    secondaryButton: { 
      text: "Learn More",
      link: "https://shahhost.net/order/index.php?/cart/business-email-pro/"
    },
    price: {
      amount: "0.75",
      period: "Per Month"
    },
    discount: "35%"
  },
  {
    title: "vps_hosting",
    description: "High-performance VPS hosting with full root access",
    primaryButton: { 
      text: "Get Started",
      link: "https://shahhost.net/order/index.php?/cart/vps-hosting/"
    },
    secondaryButton: { 
      text: "Learn More",
      link: "https://shahhost.net/order/index.php?/cart/vps-hosting/"
    },
    price: {
      amount: "5.99",
      period: "Per Month"
    },
    discount: "20%"
  }
];

const HeroCarousel = () => {
  const [api, setApi] = useState<any>(null);
  const isMobile = useIsMobile();
  const [isVisible, setIsVisible] = useState(true);
  const [slidesReady, setSlidesReady] = useState(false);

  // Check if carousel is visible
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    const carousel = document.querySelector('.hero-carousel');
    if (carousel) {
      observer.observe(carousel);
    }

    return () => {
      if (carousel) {
        observer.unobserve(carousel);
      }
    };
  }, []);

  // Set slides as ready after a short delay to ensure smoother loading
  useEffect(() => {
    const timer = setTimeout(() => {
      setSlidesReady(true);
    }, 100);
    
    return () => clearTimeout(timer);
  }, []);

  // Auto-scroll carousel when visible
  useEffect(() => {
    if (!api || !isVisible || !slidesReady) return;

    const interval = setInterval(() => {
      api.scrollNext();
    }, 7000);

    return () => clearInterval(interval);
  }, [api, isVisible, slidesReady]);

  if (!slidesReady) {
    return (
      <div className="relative w-full pb-8 md:pb-12 pt-20 md:pt-40 hero-carousel">
        <div className="animate-pulse max-w-6xl mx-auto px-4">
          <div className="h-10 bg-gray-200 rounded-md w-2/3 mb-4"></div>
          <div className="h-6 bg-gray-200 rounded-md w-1/2 mb-8"></div>
          <div className="flex gap-2">
            <div className="h-10 bg-gray-200 rounded-md w-24"></div>
            <div className="h-10 bg-gray-200 rounded-md w-24"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative w-full pb-8 md:pb-12 pt-20 md:pt-40 hero-carousel">
      <Carousel 
        className="w-full" 
        setApi={setApi}
        opts={{ 
          loop: true,
          align: "center",
          skipSnaps: false,
          duration: 50,
        }}
      >
        <CarouselContent>
          {slides.map((slide, index) => (
            <CarouselItem key={index}>
              <HeroSlide {...slide} />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
};

export default HeroCarousel;
