
import { HelpCircle, MessageSquare, Info, Globe, Mail } from "lucide-react";
import { useTranslation } from "react-i18next";

const HelpSupport = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'dr' || i18n.language === 'ps';

  const items = [
    { icon: MessageSquare, key: 'footer.support_tickets', href: 'https://www.shahhost.net/order/index.php?/tickets/new/' },
    { icon: Info, key: 'footer.faqs', href: 'https://www.shahhost.net/order/index.php?/knowledgebase/' },
    { icon: Globe, key: 'footer.knowledge_base', href: 'https://www.shahhost.net/order/index.php?/knowledgebase/' },
    { icon: Mail, key: 'footer.contact_support', href: 'https://www.shahhost.net/order/index.php?/tickets/new/' }
  ];

  return (
    <div>
      <h3 className={`text-xl font-bold mb-4 flex items-center gap-2 ${isRTL ? 'flex-row-reverse' : ''}`}>
        <HelpCircle className="h-5 w-5 text-third" />
        {t('footer.help_support')}
      </h3>
      <ul className="space-y-2">
        {items.map(({ icon: Icon, key, href }) => (
          <li key={key}>
            <a href={href} 
               className={`text-off-white hover:text-third flex items-center gap-2 ${isRTL ? 'flex-row-reverse justify-end' : ''}`}>
              <Icon className="h-4 w-4" />
              {t(key)}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HelpSupport;
