
import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "@/components/ui/toaster";
import { LanguageProvider } from "@/contexts/LanguageContext";
import ErrorBoundary from "./components/ErrorBoundary";
import Routes from "./Routes";
import "./App.css";

// Create a client with optimized settings
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      gcTime: 1000 * 60 * 30, // 30 minutes
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  // Signal that app is ready when mounted
  React.useEffect(() => {
    console.log("App component mounted - app is ready");
    
    // Use a longer delay to ensure all components have fully rendered
    // Also helps with any network requests that might be happening
    const timer = setTimeout(() => {
      if (typeof window !== 'undefined' && typeof window.appStarted === 'function') {
        console.log("Calling appStarted to signal application is ready");
        window.appStarted();
      } else {
        console.warn("appStarted function not available on window");
      }
    }, 1000); // Increased delay to ensure everything is fully loaded
    
    return () => clearTimeout(timer);
  }, []);

  // Wrap the entire app in an error boundary
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <LanguageProvider>
          <BrowserRouter>
            <Routes />
            <Toaster />
          </BrowserRouter>
        </LanguageProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
