
import { Button } from "@/components/ui/button";
import { useIsMobile } from "@/hooks/use-mobile";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

interface HeroSlideProps {
  title: string;
  description: string;
  primaryButton: {
    text: string;
    link?: string;
    onClick?: () => void;
  };
  secondaryButton: {
    text: string;
    link?: string;
    onClick?: () => void;
  };
  price?: {
    amount: string;
    period: string;
  };
  discount?: string;
}

const HeroSlide = ({
  title,
  description,
  primaryButton,
  secondaryButton,
  price,
  discount
}: HeroSlideProps) => {
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation('hero');
  const isRTL = i18n.language === 'dr' || i18n.language === 'ps';
  const [isLoaded, setIsLoaded] = useState(false);
  
  useEffect(() => {
    // Indicate slide is loaded after a brief delay
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 50);
    
    return () => clearTimeout(timer);
  }, []);
  
  const handleButtonClick = (link?: string) => {
    if (link) {
      window.location.href = link;
    }
  };

  // Convert title to lowercase and remove spaces for translation key
  const slideKey = title.toLowerCase().replace(/\s+/g, '_');

  // Provide fallback values if translations aren't ready
  const translatedTitle = t(`slides.${slideKey}.title`, {
    defaultValue: title || "Web Hosting Solutions"
  });
  
  const translatedDescription = t(`slides.${slideKey}.description`, {
    defaultValue: description || "Fast, secure, and reliable hosting services for your website"
  });
  
  if (!isLoaded) {
    return (
      <div className="relative text-white px-4 md:px-8 lg:px-12 py-4 md:py-6 lg:py-6 animate-pulse">
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-col space-y-6 items-start">
            <div className="h-8 bg-white/20 rounded-md w-64 mb-2"></div>
            <div className="h-4 bg-white/20 rounded-md w-72"></div>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="relative text-white px-4 md:px-8 lg:px-12 py-4 md:py-6 lg:py-6 animate-fade-in">
      <div className="max-w-6xl mx-auto">
        <div className={`flex flex-col space-y-6 ${isRTL ? 'items-end text-right' : 'items-start text-left'}`}>
          <div className={`space-y-4 ${isRTL ? 'text-right' : 'text-left'}`}>
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold tracking-tight drop-shadow-lg bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
              {translatedTitle}
            </h1>
            <p className="text-sm md:text-base lg:text-lg opacity-90 max-w-xl drop-shadow-md">
              {translatedDescription}
            </p>
          </div>

          <div className={`flex flex-col ${isRTL ? 'items-end' : 'items-start'} space-y-4`}>
            {price && (
              <div className="flex items-center gap-2">
                <div className="flex items-center justify-center gap-1 text-xl md:text-2xl lg:text-3xl font-bold">
                  <span className="text-[#4CAF50]">$</span>
                  <span className="text-[#4CAF50] text-2xl md:text-3xl lg:text-4xl">{price.amount}</span>
                  <div className="flex flex-col text-xs md:text-sm font-normal opacity-90">
                    <span>{t('starts_from', {
                      defaultValue: 'Starts from'
                    })}</span>
                    <span>{t('per_month', {
                      defaultValue: 'per month'
                    })}</span>
                  </div>
                </div>
                {discount && !isMobile && (
                  <div className="relative">
                    <div className="w-16 h-16 rounded-lg transform -rotate-12 absolute inset-0 bg-green-950 hover:bg-green-800" />
                    <div className="relative z-10 p-2 text-center">
                      <div className="text-[#9b87f5] font-bold text-[10px]">{t('save', {
                        defaultValue: 'Save'
                      })}</div>
                      <div className="text-white text-base font-bold">{discount}</div>
                      <div className="text-[#0EA5E9] font-bold text-[10px]">{t('off', {
                        defaultValue: 'Off'
                      })}</div>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="flex gap-2">
              <Button 
                size="sm" 
                className="bg-[#4CAF50] hover:bg-[#45a049] text-white text-xs md:text-sm px-3 py-1.5 whitespace-nowrap" 
                onClick={() => handleButtonClick(primaryButton?.link)}
              >
                {t('get_started', {
                  defaultValue: primaryButton?.text || 'Get Started'
                })}
              </Button>
              <Button 
                size="sm" 
                className="bg-[#04627a] hover:bg-[#04627a]/90 text-white text-xs md:text-sm px-3 py-1.5 whitespace-nowrap" 
                onClick={() => handleButtonClick(secondaryButton?.link)}
              >
                {t('learn_more', {
                  defaultValue: secondaryButton?.text || 'Learn More'
                })}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSlide;
