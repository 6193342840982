
import * as React from "react";
import TechnologyIcons from "@/components/pricing/icons/TechnologyIcons";
import { useTranslation } from "react-i18next";
import type { HostingGroup } from "@/types/hosting";

interface HostingHeaderProps {
  group: HostingGroup;
  techIcons?: { name: string; icon: string }[];
}

const HostingHeader: React.FC<HostingHeaderProps> = ({
  group,
  techIcons,
}) => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language as 'en' | 'dr' | 'ps';

  const getTranslatedContent = (field: 'name' | 'title' | 'description') => {
    // Try to get translation in current language
    if (group?.translations?.[currentLang]?.[field]) {
      return group.translations[currentLang][field];
    }
    // Fallback to English translation
    if (group?.translations?.['en']?.[field]) {
      return group.translations['en'][field];
    }
    // Fallback to original field value
    switch (field) {
      case 'name':
        return group?.name || '';
      case 'title':
        return '';
      case 'description':
        return group?.description || '';
    }
  };

  if (!group) return null;

  return (
    <div className="text-center mb-6">
      <h2 className="text-3xl md:text-4xl font-bold text-secondary mb-3">
        {getTranslatedContent('title') || getTranslatedContent('name')}
      </h2>
      <p className="text-gray-500 max-w-3xl mx-auto mb-4">
        {getTranslatedContent('description')}
      </p>
      {techIcons && techIcons.length > 0 && (
        <div className="mt-4">
          <TechnologyIcons icons={techIcons} />
        </div>
      )}
    </div>
  );
};

export default HostingHeader;
