
import { useForm } from "react-hook-form";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { useToast } from "../ui/use-toast";
import { useTranslation } from "react-i18next";

const Newsletter = () => {
  const { register, handleSubmit, reset } = useForm();
  const { toast } = useToast();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'dr' || i18n.language === 'ps';

  const onSubmit = (data: any) => {
    toast({
      title: "Success!",
      description: "Thank you for subscribing to our newsletter!",
    });
    reset();
  };

  return (
    <div className="max-w-md">
      <h3 className="text-lg font-semibold mb-2">{t('footer.newsletter')}</h3>
      <p className="text-off-white/80 text-sm mb-3">
        {t('footer.newsletter_desc')}
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
        <Input
          {...register("email")}
          type="email"
          placeholder={t('footer.email_placeholder')}
          className="bg-white/10 border-white/20 text-white placeholder:text-white/50 h-9"
          required
          dir={isRTL ? 'rtl' : 'ltr'}
        />
        <Button type="submit" className="w-full bg-third hover:bg-third/90 h-9">
          {t('footer.subscribe')}
        </Button>
      </form>
    </div>
  );
};

export default Newsletter;
